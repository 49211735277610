<!--
 * ======================================
 * 说明： 报告模板
 * 作者： Silence
 * 文件： custom.vue
 * 日期： 2023/10/17 23:43
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-table :data="dataList" class="p-6">
    <el-table-column
      label="序号"
      type="index"
      align="center"
      width="50">
    </el-table-column>
    <el-table-column
      prop="name"
      label="名称"
      width="220">
    </el-table-column>
    <el-table-column
      prop="url"
      label="图片">
      <template #default="scope">
        <el-image
          style="width: 120px; height: 80px"
          :src="scope.row.url"
          fit="cover"
          :preview-src-list="[scope.row.url]">
        </el-image>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {
        dataList:[{
          id:1,
          name:'一上、一下、二上正面.png',
          url:require('@/assets/report/一上、一下、二上正面.png'),
        },{
          id:2,
          name:'一二年级上学期反面.png',
          url:require('@/assets/report/一二年级第1学期反面.png'),
        },{
          id:3,
          name:'一二年级下学期反面.png',
          url:require('@/assets/report/一二年级第2学期反面.png'),
        },{
          id:4,
          name:'二下正面.png',
          url:require('@/assets/report/二下正面.png'),
        },{
          id:5,
          name:'三上正面.png',
          url:require('@/assets/report/三上正面.png'),
        },{
          id:6,
          name:'三下四五六年级正.png',
          url:require('@/assets/report/三下四五六年级正.png'),
        },{
          id:6,
          name:'三四五六年级上学期反面.png',
          url:require('@/assets/report/三四五六年级第1学期反面.png'),
        },{
          id:6,
          name:'三四五六年级下学期反面.png',
          url:require('@/assets/report/三四五六年级第2学期反面.png'),
        }]
      }
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {},
  }
</script>
<style lang="scss" scoped></style>